export const policyValues = {
  woundSchema: {
    v3: [
      "3.0",
      "b1cfdcad-f847-462d-8877-3716b0a0be65",
      "8a25bc46-7d97-4252-ae61-1b31464244ae",
      "594b31d6-2243-4758-823d-20986b6226fb"
    ],
    v4: ["af783e37-6161-4240-901d-a4d01e6f623c"],
    v4Revera: ["35808085-8756-4d76-93d6-a491b3440fe9"],
    v5: [
      "4515a7af-c16f-41a5-87bc-4ba1921cc1b9",
      "5937a29b-2c2e-43ae-bdf4-79bcf434c391",
      "95906751-ee6b-41ca-b9b8-c51d6a83fad5",
      "13e8693d-c9f2-436a-9407-4a0763e58272",
      // CareResource V5 Schema - inherits from V5, only difference is in treatments
      "bd708765-9e41-461f-9c73-b2518f283467",
      //New patient schema
      "31867e88-6bdd-41ea-958d-6a647b95425d",
      // Omeza Schema
      "6862c6c7-02fc-4da9-b379-368a64f99f43"
    ],
    v6: [
      // GHC V6 - uses same wound types as v5 but has different subtypes for skin tear and uses bruise color
      "42228b75-11c5-4edc-a2cd-19b14fd75d56",
      // V6 - Used for Body Map 2.0
      "91071df6-53dd-4667-9ac9-ac1ad1d6245b",
      "9f399177-f8d5-4402-bd4b-0e1e993e2de9",
      "1e87ccb6-9f5d-4f00-b1a6-6bef47bcd636",
      // WCC - V6 - Used for Body Map 2.0
      "e4e679f8-7f02-4233-9900-9f802c9c7cb3",
      "b3ac2703-3e7d-4b0d-979b-10f500042e09",
      // CbHSSJB/Cree schema
      "2e3f6484-cb8e-4574-9cb7-1f0895508fed",
      // Gerald Champion uses different burn degree
      "01d432c2-9df8-4690-a342-fbdfa7022a31",
      // Oroville uses different wound schema with additional/modified options in description, exudate, tissue, treatment, notes/progress files
      "a39706aa-f63e-4b13-9f92-be613d162631"
    ],
    v7: [
      // inherits from the GHC v6 schema - built for sehc
      "3a52e47b-539e-4a39-c79c-95e135f588a0",
      "d858b6df-2711-4217-8d62-4b323c4e750b"
    ],
    v8: [
      // SEHC V8 - Used for Body Map 2.0
      "e40cbbcc-6598-4474-b1c9-3bc230c65a9f",
      "23d37826-eb83-4ff1-84b3-b6a8befd0628",
      // SEHC V8 patient app schema
      "2071113b-3392-4043-ac06-5917d06f6eeb",
      // CE LHIN V8 patient app schema
      "367b60c5-f322-458a-b6cb-f4ca4ea2af92"
    ],
    // medline 1
    ML1: [
      "dc78a6b3-71e4-44f5-0410-98e4e7f91c93",
      "c9af7fae-a3d3-4b23-ac28-953d04219693"
    ],
    // v5 Schema that supports BWAT Score (with other slight translation changes)
    v5Bwat: [
      "55a3c50c-6578-44aa-1cba-3abf2799ca3a",
      "c375cd05-8dbd-4ced-9c90-f3749cc6f3d1",
      "cc3d030e-15b0-4875-8beb-ea96d26ece67",
      "a65bde05-6988-40e4-8c4d-dd3805d9ea0c",
      "a26e121b-5fd6-4808-b268-541157727733",
      "c12e7b2a-38cd-4f1b-a2ba-5f1224b96f5a",
      "6d735bab-0625-45c9-a4e0-0786f443fcfa",
      "89e36d6f-1904-40d7-975d-758b45d2d1d9",
      //HCHB V1
      "cf5c2093-1217-486e-88ea-6989f0a74922",
      //Sioux Schema
      "86abb068-5c08-46c6-916f-e912a909c18a",
      //Amedisys Schema
      "5caf82fc-1f82-4de8-8421-64ffd2746ddc",
      //CenterWell V4
      "5a2d1c82-1978-4346-a762-d40e19b9f57d",
      //VNS V1, V3
      "6f76c107-064e-4398-8187-73f4ab712c71",
      "40bc30d8-7bb7-4523-99b0-200718dfac68",
      //VNS V5
      "df61fd6d-962f-47f0-a742-4b2adcb25aa9",
      //CenterWell V5
      "4ebb52ed-b1c7-479f-be78-10b7b0485979",
      "edb4e150-468b-46dc-9f88-dca35253cffa",
      //CenterWell V6
      "7ee0a453-04f0-4705-8b09-4f2d7807ed3a",
      //CenterWell V7
      "3356ebc3-a7e5-4e08-ad7f-d7d7883f7048",
      //CenterWell V8
      "cf5b637b-1b27-4723-b94f-0a5306a94859",
      //CenterWell v9
      "f55b3277-815a-40d5-897a-abffab75bdb4",
      //Mayo v4
      "5c17c3e9-adce-4505-b377-0e0763b5376e",
      //Elara schema
      "7467e45c-d5ec-4828-82bf-4dadbcea2f29",
      "3724d2c6-30cb-4a72-bf41-50169e998c72",
      "e9636e67-b495-429d-aee2-97909f42f3a3",
      "a52065b9-e9ab-43eb-a599-648179dc8de0",
      //Medstar schema
      "8db870f8-0c6b-4b9b-8fa5-d40bad47e765"
    ],
    //LHC schema
    v5LHC: [
      "a381a6a9-780e-428c-9975-295c4f470180",
      "f070a183-9673-4e02-8d4b-cca0b17f59a3"
    ],
    // v5 Schema that supports BWAT Score and ashn v2
    v5Ashn: [
      "ae02d1b7-e5d7-49d9-9ee3-4747c5630770",
      "40c0edec-3fba-4000-aa21-2cf2652236fb",
      "76fac2be-9f7c-4bbf-9778-9a000468f686",
      "2887e1da-3037-4204-9779-f6dc8e3ddd08",
      "699568ff-6416-4aad-af97-6b79e0de218d"
    ],
    // lifeCare V6
    v6LifeCare: ["f7bba620-d84c-49b8-aabf-b7263a9ba024"],
    // Mayo Clinic V6 uses different wound schema with additional/modified options in description, exudate, tissue, surrounding tissue, treatment, notes/progress files
    v6MayoClinic: ["d7e13598-4aa5-4cf2-9eb9-854fe91b51c4"]
  },
  nonWoundSchema: {
    abpi: "6341d9fb-e218-45ba-b6d5-9d319ae4f7c7",
    braden: "6341d9fb-e218-45ba-b6d5-9d319ae4f7c6",
    fullBodySkin: [
      "5418f239-044c-4e77-987f-77138e5d11e9",
      "7526a8c4-5070-406b-cd29-e9df1968348c"
    ],
    hemoglobin: "5418f239-044c-4e77-987f-77138e5d11e8",
    toePressure: "7e7f82df-89d5-4614-9d3a-9513c23f766b",
    purs: "1d7b243d-08d7-4917-aca8-8b7ae8c2da65",
    nortonPlus: "a9aeb7a2-8db5-4eff-ac3c-a9ee18479c31"
  }
};
